import React from 'react'
import PageHeading from '../components/headings/PageHeading'
import CtaPanel from '../components/panels/CtaPanel'
import WhoItsFor from '../components/bricks/WhoItsFor'

const WhoItsForView = () => {
  return (
    <>
      <PageHeading
        title="who-its-for:page-title"
        subtitle="who-its-for:page-subtitle"
      />
      <WhoItsFor />
      <div className="brick is-clear">
        <CtaPanel />
      </div>
    </>
  )
}

export default WhoItsForView
