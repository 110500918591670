import React from 'react'
import Brick from '../bricks/Brick'
import useScrollRevealEffect from '../../../hooks/effects/useScrollRevealEffect'
import Card from '../cards/Card'
import CardContent from '../cards/CardContent'
import training from '../../../assets/images/use-cases/training-card.png'
import sales from '../../../assets/images/sales-banner.png'
import customerEnablement from '../../../assets/images/customer-enablement-banner.png'
import maintenance from '../../../assets/images/maintenance-banner.png'

const WhoItsFor = () => {
  const cardRef = useScrollRevealEffect({ stagger: 0.2 })
  const blockRef = useScrollRevealEffect()

  return (
    <Brick
      title="who-its-for:title"
      subtitle="who-its-for:subtitle"
      className="top-triangle-divider capitalize"
      clear
    >
      <div className="card-banner-grid wrapper" ref={blockRef}>
        <Card ref={cardRef} className="card-banner">
          <CardContent
            image={training}
            title="noun:training"
            subtitle="who-its-for:training:description"
            hasLink
            textLink="message:learn-more"
            cardTo="/training"
          />
        </Card>
        <Card ref={cardRef} className="card-banner">
          <CardContent
            image={maintenance}
            title="noun:maintenance-servicing"
            subtitle="who-its-for:maintenance:description"
            hasLink
            textLink="message:learn-more"
            cardTo="/maintenance"
          />
        </Card>
        <Card ref={cardRef} className="card-banner">
          <CardContent
            image={sales}
            title="sales:name"
            subtitle="who-its-for:sales:description"
            hasLink
            textLink="message:learn-more"
            cardTo="/sales"
          />
        </Card>
        <Card ref={cardRef} className="card-banner">
          <CardContent
            image={customerEnablement}
            title="noun:customer-enablement"
            subtitle="who-its-for:customer-enablement:description"
            hasLink
            textLink="message:learn-more"
            cardTo="/customer-enablement"
          />
        </Card>
      </div>
    </Brick>
  )
}

export default WhoItsFor
