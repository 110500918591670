import React from 'react'
import MainLayout from '../layouts/MainLayout'
import WhoItsForView from '../views/WhoItsForView'

const WhoItsForPage = () => {
  return (
    <MainLayout className="who-its-for">
      <WhoItsForView />
    </MainLayout>
  )
}

export default WhoItsForPage
